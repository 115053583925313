var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    p-5\n    shadow-lg\n    rounded-md\n    border border-gray-200\n    bg-white\n    overflow-hidden\n    relative\n  "},[(_vm.data.listingType.length !== 0)?_c('span',{class:("absolute " + (_vm.data.listingType.includes('global')
          ? 'bg-primary'
          : _vm.data.listingType.includes('local')
          ? 'bg-red-500'
          : '') + " text-xs px-4 text-white py-1"),style:({
      top: 0,
      right: 0
    })},[_vm._v("Top Listed")]):_vm._e(),_c('div',{staticClass:"flex flex-col items-center justify-between h-full"},[_c('div',[_c('div',{staticClass:"flex w-full items-center justify-center"},[_c('a-avatar',{staticClass:"mx-auto text-center",attrs:{"size":105,"src":_vm.data.avatar
              ? _vm.data.avatar
              : _vm.data.gender === 'male'
              ? _vm.maleImage
              : _vm.femaleImage}})],1),_c('div',{staticClass:"flex flex-col items-center mt-4"},[_c('h3',{staticClass:"\n            text-2xl\n            mb-0\n            text-center\n            font-semibold\n            text-gray-800\n            capitalize\n          "},[_vm._v(" "+_vm._s(_vm.data.role === 'lawfirm' ? _vm.data.lawFirm : _vm.data.firstName + ' ' + _vm.data.lastName)+" ")]),_c('span',{staticClass:"font-light text-sm"},[_vm._v(_vm._s(_vm.data.lawFirm))])])]),_c('div',[_c('div',{staticClass:"flex flex-wrap mt-3"},_vm._l(([].concat( _vm.data.areasOfPractice ).splice(0, 4)),function(practice){return _c('span',{key:practice._id,staticClass:"\n            text-black text-opacity-75\n            bg-gray-200\n            rounded-full\n            px-2\n            py-1\n            mx-1\n            my-1\n          ",style:({
            fontSize: '9px'
          }),attrs:{"title":practice.practice !== null ? practice.practice.title : ''}},[_vm._v(_vm._s(practice.practice !== null && practice.practice.title.length > 22 ? ((practice.practice.title.slice(0, 20)) + "...") : practice.practice.title))])}),0),_c('div',{staticClass:"flex items-baseline justify-center mt-5"},[_c('router-link',{staticClass:"\n            bg-primary\n            hover:text-white\n            text-center text-white\n            font-semibold\n            text-sm\n            px-4\n            w-28\n            py-2\n            rounded-full\n            hover:shadow-md\n            hover:bg-primary-dark\n            duration-150\n            transition-colors\n          ",attrs:{"to":("account/lawyer/" + (_vm.data.firstName.toLowerCase()) + "-" + (_vm.data.lastName.toLowerCase()) + "/" + (_vm.data._id))}},[_c('span',[_vm._v(" Contact")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }