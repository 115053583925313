<template>
  <div
    class="
      p-5
      shadow-lg
      rounded-md
      border border-gray-200
      bg-white
      overflow-hidden
      relative
    "
  >
    <span
      v-if="data.listingType.length !== 0"
      :style="{
        top: 0,
        right: 0
      }"
      :class="
        `absolute ${
          data.listingType.includes('global')
            ? 'bg-primary'
            : data.listingType.includes('local')
            ? 'bg-red-500'
            : ''
        } text-xs px-4 text-white py-1`
      "
      >Top Listed</span
    >
    <div class="flex flex-col items-center justify-between h-full">
      <div>
        <div class="flex w-full items-center justify-center">
          <a-avatar
            class="mx-auto text-center"
            :size="105"
            :src="
              data.avatar
                ? data.avatar
                : data.gender === 'male'
                ? maleImage
                : femaleImage
            "
          />
        </div>
        <div class="flex flex-col items-center mt-4">
          <h3
            class="
              text-2xl
              mb-0
              text-center
              font-semibold
              text-gray-800
              capitalize
            "
          >
            {{
              data.role === 'lawfirm'
                ? data.lawFirm
                : data.firstName + ' ' + data.lastName
            }}
          </h3>
          <span class="font-light text-sm">{{ data.lawFirm }}</span>
        </div>
      </div>
      <div>
        <div class="flex flex-wrap mt-3">
          <span
            v-for="practice in [...data.areasOfPractice].splice(0, 4)"
            :key="practice._id"
            :title="practice.practice !== null ? practice.practice.title : ''"
            :style="{
              fontSize: '9px'
            }"
            class="
              text-black text-opacity-75
              bg-gray-200
              rounded-full
              px-2
              py-1
              mx-1
              my-1
            "
            >{{
              practice.practice !== null && practice.practice.title.length > 22
                ? `${practice.practice.title.slice(0, 20)}...`
                : practice.practice.title
            }}</span
          >
        </div>
        <div class="flex items-baseline justify-center mt-5">
          <router-link
            :to="
              `account/lawyer/${data.firstName.toLowerCase()}-${data.lastName.toLowerCase()}/${
                data._id
              }`
            "
            class="
              bg-primary
              hover:text-white
              text-center text-white
              font-semibold
              text-sm
              px-4
              w-28
              py-2
              rounded-full
              hover:shadow-md
              hover:bg-primary-dark
              duration-150
              transition-colors
            "
          >
            <span> Contact</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maleImage from '../assets/images/male.svg'
import femaleImage from '../assets/images/female.svg'

export default {
  name: 'Lawyer',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      maleImage,
      femaleImage
    }
  },
  computed: {
    aop() {
      return [...this.data.areasOfPractice].splice(0, 4)
    }
  }
}
</script>

<style>
.lawyer {
  height: 200px;
  border-radius: 10px;
  @apply shadow truncate overflow-hidden flex items-center justify-center;
}
</style>
