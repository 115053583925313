<script>
import { mapState } from 'vuex'
import Lawyer from '../components/lawyer.vue'
import Navbar from '../components/navbar.vue'
import userApi from '../api/users'
import siteDataApi from '../api/site_data.js'

export default {
  components: { Lawyer, Navbar },
  data() {
    return {
      ready: false,
      searching: false,
      searchingBottom: false,
      resetting: false,
      searchKeyword: '',
      towns: [],
      users: [],
      meta: { limit: 40, offset: 0, total: '' },
      searchQuery: {
        keyword: '',
        state: '',
        gender: '',
        areasOfPractice: '',
        town: '',
        role: ''
      },
      practitioner: [
        {
          id: 'lawyer',
          title: 'Lawyer'
        },
        {
          id: 'lawfirm',
          title: 'Law Firm'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      states: state => state.data.states,
      // towns: (state) => state.data.towns,
      specialties: state => state.data.specialties
    }),
    filterState() {
      return this.states.filter(state =>
        state.title.toLowerCase().includes(this.searchQuery.state.toLowerCase())
      )
    }
  },
  created() {
    this.getAllUser(this.meta)
    this.$store.dispatch('data/getStates')
    this.$store.dispatch('data/getSpecialty')
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (
        window.innerHeight + window.scrollY - 700 >=
          document.body.offsetHeight &&
        this.ready &&
        this.meta.offset < this.meta.total
      ) {
        this.ready = false
        this.meta.offset = this.meta.offset + this.meta.limit
        this.getMoreLawyers(this.meta)
      }
    })
  },
  methods: {
    async getMoreLawyers(meta) {
      this.searchingBottom = true
      delete this.meta.total
      try {
        const users = await userApi(this.axios).getAllUser(
          { type: 'lawyer' },
          { populate: ['areasOfPractice.practice'], noAuth: true, ...meta }
        )
        this.searchingBottom = false
        this.ready = true
        this.users = [...this.users, ...users.data.regularListings]
        this.meta.total = users.data.meta.total
      } catch (error) {
        this.$notification.error({
          message: 'Unable to get data',
          description: '...'
        })
      }
      this.searchingBottom = false
      this.ready = true
    },
    async getAllUser(meta) {
      this.searching = true
      delete this.meta.total
      try {
        const users = await userApi(this.axios).getAllUser(
          { type: 'lawyer' },
          { populate: ['areasOfPractice.practice'], noAuth: true, ...meta }
        )
        this.searching = false
        this.users = [
          ...users.data.globalListings,
          ...users.data.localListings,
          ...users.data.regularListings
        ]
        this.ready = true
        this.meta.total = users.data.meta.total
      } catch (error) {
        this.$notification.error({
          message: 'Unable to get data',
          description: error.response?.message || 'Network Error'
        })
      }
    },
    handleStateChange() {
      this.getTowns()
      this.searchLawyers()
    },
    handleTownChange() {
      this.searchLawyers()
    },
    handleAreaOfPracticeChange() {
      this.searchLawyers()
    },
    handleGenderChange() {
      this.searchLawyers()
    },
    resetFilter() {
      this.searchQuery = {
        keyword: '',
        state: '',
        gender: '',
        areasOfPractice: '',
        town: '',
        role: ''
      }
      this.towns = []
      this.searchLawyers()
    },
    async searchLawyers(from) {
      this.$scrollTo('#lawyers', 300, {
        offset: -120
      })

      let params = {}
      if (this.searchQuery.keyword !== '')
        params.keyword = this.searchQuery.keyword
      if (this.searchQuery.state !== '') params.state = this.searchQuery.state
      if (this.searchQuery.town !== '') params.town = this.searchQuery.town
      if (this.searchQuery.gender !== '')
        params.gender = this.searchQuery.gender
      if (this.searchQuery.areasOfPractice !== '')
        params['areasOfPractice.practice'] = this.searchQuery.areasOfPractice
      if (this.searchQuery.role !== '') params.role = this.searchQuery.role
      if (from === 'reset') {
        params = {}
        this.resetting = true
      } else if (from === 'search') {
        this.searching = true
        params.keyword = this.searchKeyword
      } else {
        this.searching = true
      }

      const req = await userApi(this.axios).getAllUser(
        { type: 'lawyer' },
        { ...params, populate: ['areasOfPractice.practice'], noAuth: true }
      )
      // this.users = req.data.data
      this.users = [
        ...req.data.localListings,
        ...req.data.globalListings,
        ...req.data.regularListings
      ]
      this.meta = req.data.meta
      if (from === 'reset') {
        this.resetting = false
      } else {
        this.searching = false
      }
    },
    async getTowns() {
      if (this.searchQuery.state === '') {
        this.towns = []
        return
      }
      const req = await siteDataApi(this.axios).get(
        { type: 'town' },
        { state: this.searchQuery.state }
      )
      this.towns = req.data.data
    },
    filterOptionState(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    },
    filterOptionTown(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    },
    filterOptionAreaOfExpertise(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    }
  }
}
</script>

<template>
  <div>
    <navbar />
    <div
      class="banner bg-gray-400 flex items-center align-middle md:mt-20 mt-16"
    >
      <div class="md:w-8/12 w-10/12 mx-auto">
        <h4 class="font-bold md:text-7xl text-5xl text-white text-center mb-0">
          Get Legal Help
        </h4>
        <p
          class="
            text-white text-opacity-80
            font-medium
            text-center text-base
            mt-2
            mb-10
          "
        >
          Search for a Lawyer or law firm based on Area of Expertise, Name, Law
          Firm, Location...
        </p>
        <div class="md:w-8/12 w-full container mx-auto">
          <div
            class="
              flex
              items-center
              justify-center
              overflow-hidden
              rounded-md
              h-14
            "
          >
            <input
              v-model="searchKeyword"
              type="text"
              placeholder="Type your search here"
              class="
                h-full
                w-full
                px-3
                focus:outline-none
                text-base
                font-semibold
              "
              @keyup.enter="searchLawyers('search')"
            />
            <button class="focus:outline-none" @click="searchLawyers('search')">
              <img
                src="../assets/svg/search.svg"
                class="bg-primary h-full p-4 w-16"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="py-3 px-3 rounded bg-primary text-white">
      <div class="w-10/12 mx-auto">
        <div class="flex items-start justify-between">
          <p class="text-sm md:text-base">Filter by</p>
        </div>
        <div class="grid md:grid-cols-5 grid-cols-2 gap-4">
          <div>
            <p class="font-bold text-xs md:text-sm">State</p>
            <a-select
              v-model="searchQuery.state"
              placeholder="State"
              class="w-full"
              show-search
              allow-clear
              :loading="states.length === 0"
              :filter-option="filterOptionState"
              @change="handleStateChange"
            >
              <a-select-option
                v-for="state in states"
                :key="state._id"
                :value="state._id"
              >
                {{ state.title }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <p class="font-bold text-xs md:text-sm">Town</p>
            <a-select
              v-model="searchQuery.town"
              class="w-full"
              show-search
              allow-clear
              :loading="towns.length === 0"
              placeholder="Town"
              :filter-options="filterOptionState"
              @change="handleTownChange"
            >
              <a-select-option
                v-for="town in towns"
                :key="town._id"
                :value="town._id"
              >
                {{ town.title }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <p class="font-bold text-xs md:text-sm">Gender</p>
            <a-select
              v-model="searchQuery.gender"
              class="w-full"
              placeholder="Gender"
              @change="handleGenderChange"
            >
              <a-select-option key="male" value="male">Male</a-select-option>
              <a-select-option key="female" value="female"
                >Female</a-select-option
              >
            </a-select>
          </div>
          <div>
            <p class="font-bold text-xs md:text-sm">Area of Practice</p>
            <a-select
              v-model="searchQuery.areasOfPractice"
              class="w-full"
              allow-clear
              :loading="specialties.length === 0"
              placeholder="Please select an area fo expertise to search"
              :filter-options="filterOptionAreaOfExpertise"
              @change="handleAreaOfPracticeChange"
            >
              <a-select-option
                v-for="special in specialties"
                :key="special._id"
                :value="special._id"
                class="capitalize"
              >
                {{ special.title }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <p class="font-bold text-xs md:text-sm">Practitioner Type</p>
            <a-select
              v-model="searchQuery.role"
              class="w-full"
              allow-clear
              placeholder="Please select a practitioner type to search"
              :filter-options="filterOptionAreaOfExpertise"
              @change="handleAreaOfPracticeChange"
            >
              <a-select-option
                v-for="p in practitioner"
                :key="p.id"
                :value="p.id"
                class="capitalize"
              >
                {{ p.title }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="flex items-center justify-end mt-2">
          <a-button type="" @click="resetFilter"> Reset filter </a-button>
        </div>
      </div>
    </div>
    <div id="lawyers" class="py-12 px-5">
      <div class="grid grid-cols-1 relative">
        <div class="w-full md:px-10">
          <div v-if="searching" class="flex items-center justify-center">
            <a-spin />
          </div>
          <div v-else-if="!searching && users.length === 0">
            <h2 class="text-center">No lawyer matches your search</h2>
          </div>
          <div v-else class="grid md:grid-cols-4 grid-cols-1 gap-5">
            <template v-for="lawyer in users">
              <lawyer
                :key="lawyer._id"
                :loading="users.length === 0"
                :data="lawyer"
              />
            </template>
          </div>
          <div
            v-if="searchingBottom"
            class="flex items-center justify-center mt-8"
          >
            <a-spin />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.banner {
  background: linear-gradient(90deg, #20875531 25%, rgba(29, 135, 83, 0.26) 75%),
    url('../assets/images/laywer.jpg') no-repeat;
  background-size: cover;
  background-color: rgba(126, 190, 197, 0);
  height: 450px;
}
</style>
