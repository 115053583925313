import { errorResponse } from './response'

const userApi = ($axios) => {
  const http = $axios
  return {
    async getAllUser({ role }, params = {}) {
      try {
        const req = await http.get('/users', {
          params: { role, ...params },
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getAllUserAdmin({ role }, params = {}) {
      try {
        const req = await http.get('/users/admin', {
          params: { role, ...params },
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async updateUserStatus({ userId, status, reason }) {
      try {
        const req = await http.put(`/users/${userId}/status`, {
          status,
          reason,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default userApi
